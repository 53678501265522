import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Product, { headerImagePreload } from "../../layouts/ProductPage";
import { imageManager, noBase64FluidImage } from "../../components/Image";

import { videoManager } from "../../components/Video";

const VersionControl = ({ data }) => {
  const { htmlAst, frontmatter } = data.markdownRemark;
  const {
    seo, toolkit, addition, ...frontmatterData
  } = frontmatter;

  const headerImage = data.pageImages.nodes.find(({ relativePath }) => relativePath.match("__header."));

  return (
    <>
      <Helmet>
        {headerImage
          ? headerImagePreload(headerImage)
          : null}
      </Helmet>
      <div>
        <Product
          seo={seo[0]}
          {...frontmatterData}
          title={toolkit.title}
          featureList={toolkit.toolkitList}
          faq={htmlAst}
          addition={addition.list}
          additionTitle={addition.title}
          imageRenderer={imageManager({
            nodes: [
              ...data.pageImages.nodes,
              ...data.accentBannerImages.nodes,
              ...data.additionImages.nodes,
            ],
          })}
          videoRenderer={videoManager({
            nodes: [
              ...data.productVideos.nodes,
              ...data.additionVideos.nodes,
            ],
          })}
        />
      </div>
    </>
  );
};

export default VersionControl;

export const query = graphql`
  query getVersionControlToolkit($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        seo {
          title
          description
          link
          image
        }
        bannerTitle
        isComma
        afterBannerTitle
        bannerSubTitle
        href
        icon
        alt
        price
        salePrice
        benefits {
          title
          description
        }
        addition {
          title
          list {
            heading
            afterHeading
            image
            alt
            featureList {
              title
              content
              video {
                src
                type
              }
            }
          }
        }
        toolkit {
          title
          toolkitList {
            video {
              src
              type
            }
            beforeHeading
            heading
            afterHeading
            image
            testimonial
            author
            skill
            alt
            featureList {
              title
              image
              content
              contentList
              alt
              video {
                src
                type
              }
            }
          }
        }
      }
    }
    pageImages: allFile(
      filter: {
        relativePath: { glob: "images/products/version-control/**/*" }
        extension: { in: ["png", "jpg", "jpeg"] }
      }
    ) {
      nodes {
        ...noBase64FluidImage
      }
    }
    accentBannerImages:allFile(
      filter:{
        relativeDirectory: { eq: "images"},
        name: { regex:"/^xltools-add-in-for-excel-ribbon/"}    
      }      
    ) {
      nodes {
        ...noBase64FluidImage
      }
    }
    additionImages:allFile(
      filter:{
        relativeDirectory: { eq: "images/toolkits"},
        name: { regex:"/^xltools-data-prep-toolkit-preview/"}    
      }      
    ) {
      nodes {
        ...noBase64FluidImage
      }
    }
    productVideos:allFile(
      filter: {
        relativeDirectory: {
          eq: "video/products/version-control"
        }
      }
    ) {
      nodes {
        relativePath
        publicURL
        extension
      }
    }
    additionVideos:allFile(
      filter: {
        relativeDirectory: {
          eq: "video/home"
        },
        name: {
          regex: "/^xltools-popup-calendar-toolkit-animation|^xltools-data-prep-toolkit-animation/"
        }
      }
    ) {
      nodes {
        relativePath
        publicURL
        extension
      }
    }
  }
`;
